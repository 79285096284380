import { useRouteLoaderData } from "@remix-run/react";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ISaleTeamInfo {
  saleTeamName?: string;
  registrationNumber?: string;
  currentEa?: string;
  licenseNumber?: string;
  agencyLogoCustomize?: IMediaImageUrls;
}

const SalesTeamInfoFooter = ({
  saleTeamName,
  registrationNumber,
  currentEa,
  licenseNumber,
  agencyLogoCustomize,
}: ISaleTeamInfo) => {
  const { t } = useTranslation();
  const { salesTeamInfo, userConfig } = useRouteLoaderData(
    "root"
  ) as IRouteLoaderData;
  const { photo, agencyLogo: agencyLogoDefault } = userConfig;

  const agencyLogo = agencyLogoCustomize || agencyLogoDefault;

  return (
    <div className="flex flex-col flex-wrap items-start justify-start gap-x-[124px] gap-y-12 lg:gap-6 ">
      <div className="flex flex-col gap-3">
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_18}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
            className="font-bold"
          >
            {t("sales_team.heading")}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {t("sales_team.agent_name")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {saleTeamName || salesTeamInfo.name}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {t("sales_team.cea_reg_no")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {registrationNumber || salesTeamInfo.registrationNumber}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {t("sales_team.agency_name")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {currentEa || salesTeamInfo.currentEa}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {t("sales_team.agency_license_no")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.FOOTER}
          >
            {licenseNumber || salesTeamInfo.licenseNumber}
          </Typo>
        </div>
      </div>
      {agencyLogo && (
        <ImageResponsive
          imageData={agencyLogo}
          alt="Brand Logo"
          className="h-auto  origin-left"
          pictureClassName=" h-fit w-fit max-w-[200px]"
          zoom={1}
          displayDisclaimer={false}
        />
      )}
    </div>
  );
};

export default memo(SalesTeamInfoFooter);
